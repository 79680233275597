import styled from 'styled-components';
import { media } from '../../utils/device';

interface Props {
  error: string;
}

export const CollectionDateInputStyles = styled.input`
  background: #f6f6f6;

  border: ${(props: Props) => (props.error ? '2px solid #ff0000' : '1px solid #eaeaea')};
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.1em;
  width: 117px;
  height: 34px;

  ${media.mobileS} {
    font-size: 12px;
    height: 30px;
    width: 90px;
  }
`;
export const CollectionMinuteInputStyles = styled.input`
  background: #f6f6f6;
  border: ${(props: Props) => (props.error ? '2px solid #ff0000' : '1px solid #eaeaea')};
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.1em;
  width: 43px;
  height: 34px;

  ${media.mobileS} {
    font-size: 13px;
    height: 30px;
    width: 28px;
  }
  ${media.mobileM} {
    width: 28px;
  }

  ${media.mobileL} {
    width: 30px;
  }
`;

export const CollectionHourInputStyles = styled.input`
  background: #f6f6f6;
  border: ${(props: Props) => (props.error ? '2px solid #ff0000' : '1px solid #eaeaea')};
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.1em;
  width: 40px;
  height: 34px;

  ${media.mobileS} {
    font-size: 13px;
    height: 30px;
    width: 28px;
  }

  ${media.mobileM} {
    width: 28px;
  }

  ${media.mobileL} {
    width: 30px;
  }
`;

export const ToggleManualCollectionButtonStyles = styled.button`
  border-radius: 20px;
  color: #7f93ff;
  padding: 0px 7px 0px 7px;
  font-size: 14px;
  height: 28px;
  cursor: pointer;
  border: 2px solid #7f93ff;
  background: #ffffff;
`;

export const DateTimeWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AmPmSelectStyles = styled.select`
  width: 54px;
  background: #f6f6f6;
  border: ${(props: Props) => (props.error ? '2px solid #ff0000' : '1px solid #eaeaea')};
  font-size: 16px;
  line-height: 100%;
  color: #757575;
  height: 38px;

  ${media.mobileS} {
    font-size: 13px;
    height: 34px;
    width: 58px;
  }
`;

export const DateTimeInputWrapperStyles = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.tabletS} {
    font-size: 27px;
  }

  ${media.mobileL} {
    font-size: 27px;
  }

  ${media.mobileM} {
    font-size: 27px;
  }

  ${media.mobileS} {
    font-size: 24px;
  }
`;

export const TimeWrapperStyles = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
`;

export const AlreadyCollectedTextStyles = styled.div`
  font-size: 18px;
  color: #5a5a5a;

  ${media.tabletS} {
    font-size: 18px;
  }

  ${media.mobileL} {
    font-size: 18px;
  }

  ${media.mobileM} {
    font-size: 18px;
  }

  ${media.mobileS} {
    font-size: 14px;
  }
`;

export const CollectionTextStyles = styled.div`
  font-size: 27px;

  ${media.tabletS} {
    font-size: 26px;
  }

  ${media.mobileL} {
    font-size: 25px;
  }

  ${media.mobileM} {
    font-size: 22px;
  }

  ${media.mobileS} {
    font-size: 20px;
  }
`;

export const FullDateTimeInputStyles = styled.input`
  height: 50px;
  background: #f6f6f6;
  border: 1px solid #eaeaea;
  font-size: 26px;
  line-height: 100%;
  letter-spacing: 0.1em;
  width: 340px;
  text-align: center;

  ${media.tabletS} {
    width: 360px;
    font-size: 18px;
  }

  ${media.mobileL} {
    width: 280px;
  }

  ${media.mobileM} {
    width: 230px;
  }

  ${media.mobileS} {
    width: 220px;
  }
`;
