import 'reflect-metadata';

import React from 'react';

//Components
import PatientPortalDemoForm from './frontend/components/PatientPortalDemoForm';
import Header from './frontend/components/SharedFormComponents/Header';

// Styles
import { GlobalStyles } from './frontend/styles/GlobalStyles';

function App() {
  return (
    <div>
      <Header />
      <GlobalStyles />
      <PatientPortalDemoForm stepInit={1} />
    </div>
  );
}

export default App;
