import { createGlobalStyle } from 'styled-components';

// Fonts
import Jost from '../fonts/Jost-Regular.ttf';

export const GlobalStyles = createGlobalStyle`
    :root {
        --site-max-width: 1200px;
    }

    @font-face {
        font-family: 'jost, sans-serif';
        src: url('${Jost}')
    }

    html {
        height: 100%;
    }

    body {
        font-family: 'jost, sans-serif';
        font-weight: 400;
        font-style: normal;
        line-height: 1.2;
        margin: 0px;
        padding: 0px;
        width: 100%;
        background: radial-gradient(56.11% 50.46% at 50% 50.46%,#6e4596 0%,#422b5a 100%);
    }
`;
