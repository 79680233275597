import styled, { css } from 'styled-components';
import { media } from '../../../utils/device';

const sharedStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 60px 0px 0px 0px;
  background: #ffffff;
  height: 400px;
  width: 700px;
  font-size: 32px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 60px 10px;
  padding: 45px 0px 32px 0px;

  ${media.desktop} {
    height: 400px;
    width: 700px;
    font-size: 32px;
    margin: 35px 0px 35px 0px;
  }

  ${media.tabletS} {
    height: 380px;
    width: 500px;
    font-size: 24px;
    margin: 30px 0px 0px 0px;
  }

  ${media.mobileL} {
    height: 400px;
    width: 350px;
    font-size: 19px;
    margin: 60px 0px 0px 0px;
  }

  ${media.mobileM} {
    height: 380px;
    width: 300px;
    font-size: 14px;
    margin: 30px 0px 0px 0px;
  }

  ${media.mobileS} {
    height: 380px;
    width: 270px;
    font-size: 13px;
    margin: 30px 0px 0px 0px;
  }
`;

export const FormWrapperStyles = styled.div`
  ${sharedStyle}
`;

export const ErrorMessageStyles = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #ff0000;
`;

export const ErrorWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
`;
interface SpacerProps {
  height: string;
  width: string;
}

export const SpacerStyles = styled.span`
  width: ${(props: SpacerProps) => props.width}px;
  min-width: ${(props: SpacerProps) => props.width}px;
  height: ${(props: SpacerProps) => props.height}px;
  min-height: ${(props: SpacerProps) => props.height}px;
`;
export const HelperTextStyles = styled.div`
  font-size: 14px;
  text-align: center;
  color: #787a7a;

  ${media.mobileS} {
    font-size: 12px;
  }
`;

export const ThankYouSmallTextStyles = styled.div`
  font-size: 18px;
  text-align: center;
  color: #787a7a;

  ${media.mobileS} {
    font-size: 12px;
  }
`;
