import React, { useState } from 'react';

// Interfaces
type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyboardEvent = React.KeyboardEvent<HTMLDivElement>;

// Images
import ActivationCodeGraphic from '../../images/Barcode.svg';

// Constants
import { constants } from '../../utils/constants';

// Components & Styled Components
import { WelcomeTextStyles } from '../SharedFormComponents/Form/FormText.styles';
import {
  FormWrapperStyles,
  ErrorMessageStyles,
  ErrorWrapperStyles,
  SpacerStyles,
} from '../SharedFormComponents/Form/Shared.styles';
import ProgressBar from '../SharedFormComponents/ProgressBar';
import StandardButton from '../SharedFormComponents/StandardButton';
import {
  ActivationCodeInputStyles,
  ActivationCodeGraphicStyles,
  ActivationCodeSpacerStyles,
  ActivationCodeInputGraphicWrapperStyles,
} from './ActivationCodeForm.styles';

const ActivationCodeForm = ({
  nextStep,
  updateActivationCode,
  activationCode,
  step,
}: {
  nextStep: () => void;
  updateActivationCode: (event: InputEvent) => void;
  activationCode: string;
  step: number;
}): JSX.Element => {
  const [error, setError] = useState<string>('');

  const validateInput = () => {
    if (!activationCode) {
      setError(constants.activationCodeRequiredFieldError);
      return;
    }

    if (activationCode.length >= 64) {
      setError(constants.activationCodeLengthError);
      return;
    }

    // Validation passed
    // Clear error, update state & proceed
    setError('');
    nextStep();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      validateInput();
    }
  };

  return (
    <FormWrapperStyles>
      <SpacerStyles height={'10'} width={'1'} />
      <ProgressBar step={step} />
      <SpacerStyles height={'35'} width={'1'} />
      <WelcomeTextStyles>{constants.welcomeText}</WelcomeTextStyles>
      <SpacerStyles height={'45'} width={'1'} />
      <div>{constants.activationCodeFormMainText[0]}</div>
      <div>{constants.activationCodeFormMainText[1]}</div>
      <div>{constants.activationCodeFormMainText[2]}</div>
      {!error && <SpacerStyles height={'20'} width={'1'} />}
      {error && (
        <ErrorWrapperStyles>
          <SpacerStyles height={'10'} width={'1'} />
          <ErrorMessageStyles>{error}</ErrorMessageStyles>
        </ErrorWrapperStyles>
      )}
      <ActivationCodeInputGraphicWrapperStyles>
        <ActivationCodeSpacerStyles />
        <ActivationCodeInputStyles
          type="text"
          defaultValue={activationCode}
          onChange={(event: InputEvent) => updateActivationCode(event)}
          onKeyDown={(event: KeyboardEvent) => onKeyDown(event)}
          error={error}
          placeholder={constants.activationCodePlaceholderText}
        />
        <SpacerStyles height={'1'} width={'10'} />
        <ActivationCodeGraphicStyles src={ActivationCodeGraphic} alt={constants.activationCodeGraphicAltText} />
      </ActivationCodeInputGraphicWrapperStyles>
      <SpacerStyles height={'40'} width={'1'} />
      <StandardButton value={constants.continueButtonText} onClick={() => validateInput()} name={'submit-btn'} />
    </FormWrapperStyles>
  );
};

export default ActivationCodeForm;
