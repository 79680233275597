import styled from 'styled-components';
import { media } from '../../../utils/device';

interface Props {
  step: number;
}

export const ProgressBarStyles = styled.div`
  border: 1px solid #dadada;
  border-radius: 30px;
  width: 600px;

  div {
    background-color: #64eebc;
    width: calc(${(props: Props) => props.step * 33.34}%);
    height: 15px;
    border-radius: 10px;
  }

  ${media.tabletS} {
    width: 420px;
  }

  ${media.mobileL} {
    width: 280px;
  }

  ${media.mobileM} {
    width: 210px;
  }

  ${media.mobileS} {
    width: 200px;
  }
`;

export const ProgressBarWrapperStyles = styled.div`
  display: flex;
  align-items: center;
`;
