import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// Constants
import { constants } from '../../utils/constants';

// Interfaces
type SubmitCallback = () => void;

// Components & Styled Components
import { ThankYouTextStyles } from '../SharedFormComponents/Form/FormText.styles';
import { FormWrapperStyles, SpacerStyles, ThankYouSmallTextStyles } from '../SharedFormComponents/Form/Shared.styles';
import ProgressBar from '../SharedFormComponents/ProgressBar';

const ThankYouForm = ({
  step,
  activationCode,
  collectedDate,
}: {
  step: number;
  activationCode: string;
  collectedDate: Date;
}): JSX.Element => {
  const handleSubmit: SubmitCallback = async () => {
    const uid = uuidv4();
    const dateNow = new Date();
    const utcDateNow = dateNow.toUTCString();

    // Env consts
    const api_url = process.env.REACT_APP_API_URL;

    try {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');

      // We want to capture the time the end user entered
      // Into UTC to capture the timezone in the UTC datetimeoffset object
      const utcCollectedDate = collectedDate.toUTCString();

      await fetch(`${api_url}/patient-demo`, {
        mode: 'cors',
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          id: uid,
          barcode: activationCode,
          user_collection_datetime: utcCollectedDate,
          user_submit_datetime: utcDateNow,
        }),
      });
    } catch (error) {
      console.log('error: ' + error);
      // TODO have some kind of non-console logging here that we can monitor
    }
  };

  // All form data has been validated & updated
  // Call submit action
  handleSubmit();

  return (
    <div>
      <FormWrapperStyles>
        <ProgressBar step={step} />
        <SpacerStyles height={'105'} width={'1'} />
        <ThankYouTextStyles>{constants.thankYouText}</ThankYouTextStyles>
        <SpacerStyles height={'35'} width={'1'} />
        <ThankYouSmallTextStyles>Please follow the instructions in your kit</ThankYouSmallTextStyles>
        <ThankYouSmallTextStyles>to complete your sample collection.</ThankYouSmallTextStyles>
      </FormWrapperStyles>
    </div>
  );
};

export default ThankYouForm;
