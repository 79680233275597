import 'reflect-metadata';
import 'dotenv/config';
import React, { useState } from 'react';

// Components
import ActivationCodeForm from '../ActivationCodeForm';
import CollectionDateForm from '../CollectionDateForm';
import ThankYouForm from '../ThankYouForm';

// Interfaces
type InputEvent = React.ChangeEvent<HTMLInputElement>;

// Styles
import { PatientPortalDemoFormStyles } from './PatientPortalDemoForm.styles';

const PatientPortalDemoForm = ({ stepInit }: { stepInit: number }): JSX.Element => {
  const [activationCode, setActivationCode] = useState('');
  const [collectedDate, setCollectedDate] = useState(new Date());
  const [step, setStep] = useState(stepInit);

  const onNextStep = () => setStep((prevStep) => prevStep + 1);
  const onPrevStep = () => setStep((prevStep) => prevStep - 1);

  const updateActivationCode = (event: InputEvent): void => {
    event.target.value.trim();
    setActivationCode(event.target.value);
  };

  const updateCollectedDate = (date?: Date): void => {
    if (date) {
      date.toUTCString();
      setCollectedDate(date);
    } else {
      const dateNow = new Date();
      setCollectedDate(dateNow);
    }

    // Data has been validated and set
    // Increase step count
    onNextStep();
  };

  const renderSwitch = (step: number): JSX.Element => {
    switch (step) {
      case 1:
        return (
          <ActivationCodeForm
            step={step}
            nextStep={onNextStep}
            updateActivationCode={updateActivationCode}
            activationCode={activationCode}
          />
        );
      case 2:
        return <CollectionDateForm step={step} prevStep={onPrevStep} updateCollectedDate={updateCollectedDate} />;
      case 3:
        return <ThankYouForm step={step} activationCode={activationCode} collectedDate={collectedDate} />;
      default:
        return (
          <ActivationCodeForm
            step={step}
            nextStep={onNextStep}
            updateActivationCode={updateActivationCode}
            activationCode={activationCode}
          />
        );
    }
  };

  return <PatientPortalDemoFormStyles>{renderSwitch(step)}</PatientPortalDemoFormStyles>;
};

export default PatientPortalDemoForm;
